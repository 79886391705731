import React from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { encodeJsonLD } from "react-safe-json-ld";
import { TSEO } from "./SEO.types";

const SEO: React.FC<TSEO> = (props) => {
  const { i18n } = useTranslation();

  const {
    title = [i18n.t("pages.home.head.title"), "TransKing.eu"].join(" - "),
    description = i18n.t("pages.home.head.description"),
    url = [window.location.origin, window.location.pathname].join(""),
    robots = "follow, index, max-snippet:-1, max-video-preview:-1, max-image-preview:large",
    script = [],
    link = [],
    structuredData = []
  } = props;

  return (
    <Helmet
      title={title}
      meta={[
        { name: "description", content: description },
        { property: "og:url", content: url },
        { property: "og:title", content: title },
        { property: "og:description", content: description },
        { name: "twitter:title", content: title },
        { name: "twitter:description", content: description },
        { name: "robots", content: robots }
      ]}
      link={[{ rel: "canonical", href: url }, ...link]}
      script={[
        {
          type: "application/ld+json",
          innerHTML: encodeJsonLD({
            "@context": "https://schema.org",
            "@graph": structuredData
          })
        },
        ...script
      ]}
    />
  );
};

export default SEO;
