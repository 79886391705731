import React from "react";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import HelpTemplate from "@shared/ui/templates/HelpTemplate";
import Typography from "@shared/ui/atoms/Typography";
import Panel from "@shared/ui/molecules/Panel/Panel";
import { useTranslation } from "react-i18next";
import jsxToString from "jsx-to-string";

const WhatAreTheBenefitsOfTransking = () => {
  const { i18n } = useTranslation();

  return (
    <HelpTemplate
      seoProps={{
        title: i18n.t("pages.whatAreTheBenefitsOfTransking.head.title"),
        structuredData: [
          {
            "@type": "FAQPage",
            mainEntity: [
              {
                "@type": "Question",
                name: "Jakie korzyści daje mi Transking?",
                acceptedAnswer: {
                  "@type": "Answer",
                  text: jsxToString(
                    <div>
                      <p>
                        <strong>Oszczędzasz %</strong>
                        <br />
                        Dzięki naszej aplikacji ogłoszenie trafia do tysięcy
                        osób, które zmierzają w tym samym kierunku co Ty, a więc
                        oznacza to oszczędność Twoich pieniędzy.
                      </p>
                      <p>
                        <strong>Oszczędzasz %</strong>
                        <br />
                        Dzięki naszej aplikacji ogłoszenie trafia do tysięcy
                        osób, które zmierzają w tym samym kierunku co Ty, a więc
                        oznacza to oszczędność Twoich pieniędzy.
                      </p>
                      <p>
                        <strong>Wygoda to podstawa</strong>
                        <br />
                        Wystarczy że wystawiasz zlecenie na stronie, później
                        czekasz na oferty od przewoźników.
                      </p>
                      <p>
                        <strong>Nic nie płacisz</strong>
                        <br />
                        Wystaw swoje zlecenie za darmo.
                      </p>
                    </div>
                  )
                }
              }
            ]
          }
        ]
      }}
    >
      <Grid item xs={12} sm={12} md={8} mt={3}>
        <Panel>
          <Stack spacing={2}>
            <Typography variant="h5" component="h1">
              Jakie korzyści daje mi Transking?
            </Typography>

            <Box pt={1}>
              <Stack spacing={2}>
                <Typography variant="h6" component="h2">
                  Oszczędzasz %
                </Typography>

                <Typography>
                  Dzięki naszej aplikacji ogłoszenie trafia do tysięcy osób,
                  które zmierzają w tym samym kierunku co Ty, a więc oznacza to
                  oszczędność Twoich pieniędzy.
                </Typography>

                <Typography variant="h6" component="h2">
                  Wygoda to podstawa
                </Typography>

                <Typography>
                  Wystarczy że wystawiasz zlecenie na stronie, później czekasz
                  na oferty od przewoźników.
                </Typography>

                <Typography variant="h6" component="h2">
                  Nic nie płacisz
                </Typography>

                <Typography>Wystaw swoje zlecenie za darmo.</Typography>
              </Stack>
            </Box>
          </Stack>
        </Panel>
      </Grid>
    </HelpTemplate>
  );
};

export default WhatAreTheBenefitsOfTransking;
