import api from "./api";
import { TAuction, TResource } from "./types";

export const auctionApi = api.injectEndpoints({
  endpoints: (builder) => ({
    closeAuction: builder.mutation<TResource<TAuction>, any>({
      query: ({ id }) => ({
        url: `/auctions/${id}/close`,
        method: "POST",
        body: {
          data: {
            attributes: {}
          }
        }
      }),
      invalidatesTags: ["Auction"]
    })
  })
});

export const { useCloseAuctionMutation } = auctionApi;
