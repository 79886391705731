import React from "react";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import HelpTemplate from "@shared/ui/templates/HelpTemplate";
import Typography from "@shared/ui/atoms/Typography";
import Panel from "@shared/ui/molecules/Panel/Panel";
import { useHistory } from "react-router-dom";
import routes from "@routes/routes";
import Link from "@shared/ui/atoms/Link";
import { useTranslation } from "react-i18next";
import jsxToString from "jsx-to-string";

const HowToCreateAnOrder = () => {
  const history = useHistory();
  const { i18n } = useTranslation();

  const newOrder = () => {
    history.push(routes.root.transportOrders.new.category.pattern);
  };

  return (
    <HelpTemplate
      seoProps={{
        title: i18n.t("pages.howToCreateAnOrder.head.title"),
        structuredData: [
          {
            "@type": "FAQPage",
            mainEntity: [
              {
                "@type": "Question",
                name: "Jak stworzyć zlecenie?",
                acceptedAnswer: {
                  "@type": "Answer",
                  text: jsxToString(
                    <div>
                      <ul>
                        <li>
                          Przejdź do zakładki <strong>Zleć transport</strong>
                        </li>
                        <li>
                          W pierwszym kroku uzupełnij brakujące dane tzn. trasa
                          i terminy przesyłki
                        </li>
                        <li>
                          W kolejnym kroku możesz podać dodatkowe informacje,
                          wybrać preferencje ofert
                        </li>
                      </ul>
                      <p>
                        Udało się! Teraz poczekaj na pierwsze oferty od
                        przewoźników.
                      </p>
                    </div>
                  )
                }
              }
            ]
          }
        ]
      }}
    >
      <Grid item xs={12} sm={12} md={8} mt={3}>
        <Panel>
          <Stack spacing={2}>
            <Typography variant="h5" component="h1">
              Jak stworzyć zlecenie?
            </Typography>
            <Box pt={1}>
              <Stack spacing={2}>
                <ul style={{ listStyleType: "disc" }}>
                  <li>
                    <Typography>
                      Przejdź do zakładki{" "}
                      <Box display="inline-block">
                        <Typography color="text.turquoise.main">
                          <Link onClick={newOrder}>Zleć transport</Link>
                        </Typography>
                      </Box>
                    </Typography>
                  </li>
                  <li>
                    <Typography>
                      W pierwszym kroku uzupełnij brakujące dane tzn. trasa i
                      terminy przesyłki
                    </Typography>
                  </li>
                  <li>
                    <Typography>
                      W kolejnym kroku możesz podać dodatkowe informacje, wybrać
                      preferencje ofert
                    </Typography>
                  </li>
                </ul>
                <Typography>
                  Udało się! Teraz poczekaj na pierwsze oferty od przewoźników.
                </Typography>
              </Stack>
            </Box>
          </Stack>
        </Panel>
      </Grid>
    </HelpTemplate>
  );
};

export default HowToCreateAnOrder;
