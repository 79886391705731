import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { setCredentials, removeCredentials } from "@store/authSlice";
import qs from "qs";
import deserializeApiResponse from "./utils/deserializeApiResponse";

type ApplicationError = {
  errors: any[];
};

export function isApplicationError(data: unknown): data is ApplicationError {
  return typeof data === "object" && data != null && "errors" in data;
}

const baseQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_CORE_API_URL,
  prepareHeaders: (headers, { getState }: any) => {
    headers.set("Content-Type", "application/json");
    headers.set("Accept", "application/json");

    const { accessToken } = getState().auth;

    if (accessToken) {
      headers.set("Authorization", `Bearer ${accessToken}`);
    }

    return headers;
  },
  paramsSerializer: (params) => {
    return qs.stringify(params);
  }
});

const baseQueryWithDeserialize = async (
  args: any,
  api: any,
  extraOptions: any
) => {
  const result = await baseQuery(args, api, extraOptions);

  return { ...result, data: await deserializeApiResponse(result.data) };
};

const baseQueryWithReauth = async (args: any, api: any, extraOptions: any) => {
  let result = await baseQueryWithDeserialize(args, api, extraOptions);

  if (result.error?.status !== 401) return result;

  if (isApplicationError(result.error?.data)) {
    if (result.error.data.errors[0].code === "wrong_token") {
      api.dispatch(removeCredentials());

      return result;
    }

    if (result.error.data.errors[0].code !== "token_expired") {
      return result;
    }
  }

  const { refreshToken } = api.getState().auth;

  if (!refreshToken) return result;

  const refreshResult = await baseQueryWithDeserialize(
    {
      url: "/auth/refresh_token",
      method: "POST",
      body: {
        data: {
          attributes: {
            refresh_token: refreshToken
          }
        }
      }
    },
    api,
    extraOptions
  );

  if (refreshResult?.data?.data) {
    api.dispatch(
      setCredentials({
        user: refreshResult.data.data,
        accessToken: refreshResult.data.meta.access_token,
        refreshToken: refreshResult.data.meta.refresh_token
      })
    );

    result = await baseQueryWithDeserialize(args, api, extraOptions);
  } else {
    api.dispatch(removeCredentials());
  }

  return result;
};

const api = createApi({
  baseQuery: baseQueryWithReauth,
  tagTypes: [
    "PublicTransportOrder",
    "TransportOrder",
    "Message",
    "Offer",
    "Session",
    "Discussion",
    "User",
    "Review",
    "Invoice",
    "Company",
    "PurchaseOrder",
    "PublicReview",
    "PublicUser",
    "Auction"
  ],
  keepUnusedDataFor: 0,
  endpoints: () => ({})
});

export default api;
