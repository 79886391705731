import React from "react";
import Box from "@mui/material/Box";
import Typography from "@shared/ui/atoms/Typography";
import { Divider, Stack, ButtonGroup, MenuItem } from "@mui/material";
import CustomAccordion from "@shared/ui/atoms/CustomAccordion/CustomAccordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MultiplyIcon from "@shared/ui/atoms/icons/MultiplyIcon/MultiplyIcon";
import OriginMarkerIcon from "@shared/ui/atoms/icons/OriginMarkerIcon";
import DestinationMarkerIcon from "@shared/ui/atoms/icons/DestinationMarkerIcon/DestinationMarkerIcon";
import Button from "@shared/ui/atoms/Button/Button";
import { useHistory } from "react-router-dom";
import routes from "@routes/routes";
import formattedAddress from "@shared/utils/formattedAddress";
import convertDate from "@shared/utils/convertDate";
import formattedAddressWithComma from "@shared/utils/formattedAddressWithComma";
import TreasureIcon from "@shared/ui/atoms/icons/TreasureIcon";
import TravelerIcon from "@shared/ui/atoms/icons/TravelerIcon";
import OrdersIcon from "@shared/ui/atoms/icons/OrdersIcon";
import { ITransportOrderLineItem } from "@pages/transport-orders/new/category/category.types";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { Trans } from "react-i18next";
import ButtonMenu from "@shared/ui/molecules/ButtonMenu";
import { useCloseAuctionMutation } from "@api/TranstubeCore/auctionsApi";
import OthersTransportOrderLineItem from "../OthersTransportOrderLineItem";
import useStyles from "./TransportOrderListItem.styles";

const TransportOrderListItem: React.FC<any> = (props) => {
  const { transportOrder, refetchTransportOrders } = props;
  const history = useHistory();
  const { classes } = useStyles();
  const [openBox, setOpenBox] = React.useState(false);
  const [closeAuction] = useCloseAuctionMutation();
  const [loading, setLoading] = React.useState(false);

  const handleCloseAuction = async (auctionId: any) => {
    setLoading(true);
    await closeAuction({ id: auctionId });

    await refetchTransportOrders();
    setLoading(false);
  };

  const open = () => {
    history.push(
      routes.root.transportOrders.transportOrder({ id: transportOrder.id })
    );
  };

  const displayData = () => {
    if (transportOrder.startOn === null || transportOrder.endOn === null)
      return (
        <Typography variant="body2" color="text.grey.main">
          Termin elastyczny
        </Typography>
      );

    return (
      <>
        <Typography variant="body2" color="text.grey.main">
          {convertDate(transportOrder.startOn)} -{" "}
          {convertDate(transportOrder.endOn)}
        </Typography>
      </>
    );
  };

  const categoryPeople = transportOrder?.transportOrderLineItems?.some(
    (transportOrderLineItem: any) =>
      transportOrderLineItem?.category === "people"
  );

  const categoryOthers = transportOrder?.transportOrderLineItems?.some(
    (transportOrderLineItem: any) =>
      transportOrderLineItem?.category === "others"
  );

  const filteredArrayLength = transportOrder?.transportOrderLineItems
    ?.filter((transportOrderLineItem: any) => {
      return transportOrderLineItem.category.includes("others");
    })
    .reduce((l: number, p: ITransportOrderLineItem) => l + p.quantity, 0);

  const clickArrow = () => {
    setOpenBox(true);
  };

  const close = () => {
    setOpenBox(false);
  };

  const displayCategories = () => {
    if (filteredArrayLength === 1) {
      return (
        <Stack spacing={2}>
          {transportOrder.transportOrderLineItems.map(
            (transportOrderLineItem: ITransportOrderLineItem) =>
              transportOrderLineItem.category === "others" ? (
                <React.Fragment
                  key={`transportOrderLineItem-${transportOrderLineItem.id}`}
                >
                  <OthersTransportOrderLineItem
                    transportOrderLineItem={transportOrderLineItem}
                    sx={{ padding: 0 }}
                  />
                </React.Fragment>
              ) : (
                <React.Fragment
                  key={`transportOrderLineItem-${transportOrderLineItem.id}`}
                >
                  <Box display="flex">
                    <Stack spacing={2} direction="row" alignItems="center">
                      <Box
                        className={classes.box}
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                      >
                        <TravelerIcon />
                      </Box>

                      <Box display="inline">
                        <Stack spacing={1}>
                          <Box>
                            <Typography
                              variant="body2"
                              color="text.turquoise.dark"
                            >
                              <strong>Ilość</strong>
                            </Typography>
                          </Box>
                          <Box display="flex">
                            <Typography variant="body2" color="text.grey.main">
                              {transportOrderLineItem?.quantity} os.
                            </Typography>
                          </Box>
                        </Stack>
                      </Box>
                    </Stack>
                  </Box>
                </React.Fragment>
              )
          )}
        </Stack>
      );
    }

    if (filteredArrayLength === 0) {
      return (
        <Stack spacing={2}>
          {transportOrder.transportOrderLineItems.map(
            (transportOrderLineItem: ITransportOrderLineItem) =>
              transportOrderLineItem.category === "people" && (
                <React.Fragment
                  key={`transportOrderLineItem-${transportOrderLineItem.id}`}
                >
                  <Box display="flex">
                    <Stack spacing={2} direction="row" alignItems="center">
                      <Box
                        className={classes.box}
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                      >
                        <TravelerIcon />
                      </Box>

                      <Box display="inline">
                        <Stack spacing={1}>
                          <Box>
                            <Typography
                              variant="body2"
                              color="text.turquoise.dark"
                            >
                              <strong>Ilość</strong>
                            </Typography>
                          </Box>
                          <Box display="flex">
                            <Typography variant="body2" color="text.grey.main">
                              {transportOrderLineItem?.quantity} os.
                            </Typography>
                          </Box>
                        </Stack>
                      </Box>
                    </Stack>
                  </Box>
                </React.Fragment>
              )
          )}
        </Stack>
      );
    }

    return (
      <Stack spacing={2}>
        {transportOrder.transportOrderLineItems.map(
          (transportOrderLineItem: ITransportOrderLineItem) =>
            transportOrderLineItem.category === "people" && (
              <React.Fragment
                key={`transportOrderLineItem-${transportOrderLineItem.id}`}
              >
                <Box display="flex">
                  <Stack spacing={2} direction="row" alignItems="center">
                    <Box
                      className={classes.box}
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                    >
                      <TravelerIcon />
                    </Box>

                    <Box display="inline">
                      <Stack spacing={1}>
                        <Box>
                          <Typography
                            variant="body2"
                            color="text.turquoise.dark"
                          >
                            <strong>Ilość</strong>
                          </Typography>
                        </Box>
                        <Box display="flex">
                          <Typography variant="body2" color="text.grey.main">
                            {transportOrderLineItem?.quantity} os.
                          </Typography>
                        </Box>
                      </Stack>
                    </Box>
                  </Stack>
                </Box>
              </React.Fragment>
            )
        )}
        <Box display="flex">
          <Stack spacing={2} direction="row" alignItems="center">
            <Box
              className={classes.box}
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <OrdersIcon />
            </Box>
            <Box display="inline">
              <Stack spacing={1} direction="column">
                <Typography variant="body2" color="text.turquoise.dark">
                  <strong>Ilość</strong>
                </Typography>

                <Stack spacing={0.5} direction="row">
                  <Typography variant="body2" color="text.grey.main">
                    {filteredArrayLength}
                  </Typography>
                  <Typography variant="body2" color="text.grey.main">
                    szt.
                  </Typography>
                </Stack>
              </Stack>
            </Box>
            {openBox === false ? (
              <Box>
                <KeyboardArrowDownIcon
                  sx={{ cursor: "pointer" }}
                  onClick={() => clickArrow()}
                />
              </Box>
            ) : (
              <KeyboardArrowUpIcon
                sx={{ cursor: "pointer" }}
                onClick={() => close()}
              />
            )}
          </Stack>
        </Box>

        {openBox === true ? (
          transportOrder.transportOrderLineItems.map(
            (transportOrderLineItem: ITransportOrderLineItem) =>
              transportOrderLineItem.category === "others" && (
                <React.Fragment
                  key={`transportOrderLineItem-${transportOrderLineItem.id}`}
                >
                  <Divider light />

                  <OthersTransportOrderLineItem
                    transportOrderLineItem={transportOrderLineItem}
                    sx={{ padding: 0 }}
                  />
                </React.Fragment>
              )
          )
        ) : (
          <></>
        )}
      </Stack>
    );
  };

  const moreInformation = () => {
    if (transportOrder.extraInformation !== "") {
      return (
        <>
          <Box sx={{ width: { xs: "100%", sm: "100%", md: "50%" } }}>
            <Stack spacing={2}>
              <Box>
                <Stack spacing={1} textAlign="left">
                  <Typography variant="body2" color="text.turquoise.dark">
                    <strong>Numer</strong>
                  </Typography>

                  <Typography variant="body2" color="text.grey.main">
                    {transportOrder.number}
                  </Typography>
                </Stack>
              </Box>
              <Box>
                <Stack spacing={1} textAlign="left">
                  <Typography variant="body2" color="text.turquoise.dark">
                    <strong>Informacje dodatkowe</strong>
                  </Typography>

                  <Typography
                    variant="body2"
                    color="text.grey.main"
                    sx={{ wordBreak: "break-word" }}
                  >
                    {transportOrder.extraInformation}
                  </Typography>
                </Stack>
              </Box>
              <Box>
                <Stack spacing={1} textAlign="left">
                  <Typography variant="body2" color="text.turquoise.dark">
                    <strong>Termin realizacji</strong>
                  </Typography>

                  {displayData()}
                </Stack>
              </Box>
              <Box>
                {" "}
                <Stack spacing={1} textAlign="left">
                  <Typography variant="body2" color="text.turquoise.dark">
                    <strong>Przesyłki</strong>
                  </Typography>
                  {displayCategories()}
                </Stack>
              </Box>
              <Box
                sx={{ display: { sm: "block", md: "none" } }}
                textAlign="center"
              >
                {transportOrder.type === "transportOrder" ? (
                  <ButtonGroup>
                    <Button
                      size="small"
                      variant="outlined"
                      sx={{
                        pointerEvents: "auto"
                      }}
                    >
                      Zobacz
                    </Button>

                    <ButtonMenu size="small" variant="outlined">
                      <MenuItem
                        onClick={() =>
                          handleCloseAuction(transportOrder.auction.id)
                        }
                        disabled={
                          loading || transportOrder.auction.status === "closed"
                        }
                      >
                        <Typography variant="body2" color="text.turquoise.dark">
                          Wycofaj zlecenie
                        </Typography>
                      </MenuItem>
                    </ButtonMenu>
                  </ButtonGroup>
                ) : (
                  <Button variant="outlined" onClick={open}>
                    Zobacz
                  </Button>
                )}
              </Box>
            </Stack>
          </Box>
        </>
      );
    }

    return (
      <Box sx={{ width: { xs: "100%", sm: "100%", md: "50%" } }}>
        <Stack spacing={2}>
          <Box>
            <Stack spacing={1} textAlign="left">
              <Typography variant="body2" color="text.turquoise.dark">
                <strong>Numer</strong>
              </Typography>

              <Typography variant="body2" color="text.grey.main">
                {transportOrder.number}
              </Typography>
            </Stack>
          </Box>
          <Box>
            <Stack spacing={1} textAlign="left">
              <Typography variant="body2" color="text.turquoise.dark">
                <strong>Termin realizacji</strong>
              </Typography>

              <Typography variant="body2" color="text.grey.main">
                {displayData()}
              </Typography>
            </Stack>
          </Box>
          <Box>
            {" "}
            <Stack spacing={1} textAlign="left">
              <Typography variant="body2" color="text.turquoise.dark">
                <strong>Przesyłki</strong>
              </Typography>
              {displayCategories()}
            </Stack>
          </Box>
          <Box sx={{ display: { sm: "block", md: "none" } }} textAlign="center">
            {transportOrder.type === "transportOrder" ? (
              <ButtonGroup>
                <Button
                  size="small"
                  variant="outlined"
                  sx={{
                    pointerEvents: "auto"
                  }}
                >
                  Zobacz
                </Button>

                <ButtonMenu size="small" variant="outlined">
                  <MenuItem
                    onClick={() =>
                      handleCloseAuction(transportOrder.auction.id)
                    }
                    disabled={
                      loading || transportOrder.auction.status === "closed"
                    }
                  >
                    <Typography variant="body2" color="text.turquoise.dark">
                      Wycofaj zlecenie
                    </Typography>
                  </MenuItem>
                </ButtonMenu>
              </ButtonGroup>
            ) : (
              <Button variant="outlined" onClick={open}>
                Zobacz
              </Button>
            )}
          </Box>
        </Stack>
      </Box>
    );
  };

  const categories = () => {
    if (categoryPeople === true && categoryOthers === true) {
      return (
        <Box
          className={classes.box}
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <TreasureIcon />
        </Box>
      );
    }

    if (categoryPeople === true) {
      return (
        <Stack spacing={2} direction="row">
          <Box
            className={classes.box}
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <TravelerIcon />
          </Box>
          <Box alignSelf="center" display="flex">
            <MultiplyIcon />
          </Box>
          {transportOrder.transportOrderLineItems.map((item: any) => (
            <Box alignSelf="center" display="flex" key={item.id}>
              <Typography variant="body2" color="text.grey.main">
                {item.quantity}
              </Typography>
            </Box>
          ))}
        </Stack>
      );
    }

    if (categoryOthers === true) {
      return (
        <Stack spacing={2} direction="row">
          <Box
            className={classes.box}
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <OrdersIcon />
          </Box>
          <Box alignSelf="center" display="flex">
            <MultiplyIcon />
          </Box>
          {transportOrder.transportOrderLineItems.map((item: any) => (
            <Box alignSelf="center" display="flex" key={item.id}>
              <Typography variant="body2" color="text.grey.main">
                {item.quantity}
              </Typography>
            </Box>
          ))}
        </Stack>
      );
    }

    return <></>;
  };

  const [accordionOpen, setAccordionOpen] = React.useState(false);

  return (
    <CustomAccordion key={transportOrder.id} accordionOpen={accordionOpen}>
      <AccordionSummary
        expandIcon={
          <ExpandMoreIcon
            style={{ cursor: "pointer" }}
            onClick={() => setAccordionOpen(!accordionOpen)}
          />
        }
      >
        <div style={{ width: "100%" }}>
          <Box
            sx={{
              display: { xs: "none", sm: "none", md: "flex" },
              alignItems: { md: "center" }
            }}
          >
            <Box sx={{ width: { md: "15%" } }}>{categories()}</Box>

            <Box sx={{ width: { md: "25%" } }}>
              <Stack spacing={1} direction="row">
                <Box alignSelf="center">
                  <OriginMarkerIcon />
                </Box>
                <Typography variant="body2" color="text.grey.main">
                  {formattedAddress(transportOrder.fromLocation)}
                </Typography>
              </Stack>
            </Box>
            <Box sx={{ width: { md: "25%" } }}>
              <Stack spacing={1} direction="row">
                <Box alignSelf="center">
                  <DestinationMarkerIcon />
                </Box>
                <Typography variant="body2" color="text.grey.main">
                  {formattedAddress(transportOrder.toLocation)}
                </Typography>
              </Stack>
            </Box>
            <Box sx={{ width: { md: "20%" } }}>
              <Typography variant="body2" color="text.grey.main">
                {convertDate(transportOrder.createdAt)}
              </Typography>
            </Box>
            <Box sx={{ width: { md: "15%" } }}>
              {transportOrder.type === "transportOrder" ? (
                <ButtonGroup>
                  <Button
                    size="small"
                    variant="outlined"
                    onClick={() => setAccordionOpen(!accordionOpen)}
                  >
                    Zobacz
                  </Button>

                  <ButtonMenu size="small" variant="outlined">
                    <MenuItem
                      onClick={() =>
                        handleCloseAuction(transportOrder.auction.id)
                      }
                      disabled={
                        loading || transportOrder.auction.status === "closed"
                      }
                    >
                      <Typography variant="body2" color="text.turquoise.dark">
                        Wycofaj zlecenie
                      </Typography>
                    </MenuItem>
                  </ButtonMenu>
                </ButtonGroup>
              ) : (
                <Button variant="outlined" onClick={open}>
                  Zobacz
                </Button>
              )}
            </Box>
          </Box>
          <Box sx={{ display: { md: "none" } }}>
            <Stack spacing={2}>
              <Box>{categories()}</Box>
              <Box>
                <Stack spacing={1}>
                  <Stack direction="row" spacing={1} alignItems="center">
                    <OriginMarkerIcon />

                    <Typography variant="body2" color="text.turquoise.dark">
                      <strong>
                        <Trans i18nKey="labels.from_location" />
                      </strong>
                    </Typography>
                  </Stack>
                  <Typography variant="body2" color="text.grey.main">
                    {formattedAddressWithComma(transportOrder.fromLocation)}
                  </Typography>
                </Stack>
              </Box>
              <Box>
                <Stack spacing={1}>
                  <Stack direction="row" spacing={1} alignItems="center">
                    <DestinationMarkerIcon />

                    <Typography variant="body2" color="text.turquoise.dark">
                      <strong>
                        <Trans i18nKey="labels.to_location" />
                      </strong>
                    </Typography>
                  </Stack>
                  <Typography variant="body2" color="text.grey.main">
                    {formattedAddressWithComma(transportOrder.toLocation)}
                  </Typography>
                </Stack>
              </Box>
            </Stack>

            <Box
              sx={{ width: { md: "15%" }, display: { xs: "none", sm: "none" } }}
              textAlign="center"
            >
              {transportOrder.type === transportOrder ? (
                <ButtonGroup>
                  <Button size="small" variant="outlined">
                    Zobacz
                  </Button>

                  <ButtonMenu size="small" variant="outlined">
                    <MenuItem
                      onClick={() =>
                        handleCloseAuction(transportOrder.auction.id)
                      }
                      disabled={
                        loading || transportOrder.auction.status === "closed"
                      }
                    >
                      <Typography variant="body2" color="text.turquoise.dark">
                        Wycofaj zlecenie
                      </Typography>
                    </MenuItem>
                  </ButtonMenu>
                </ButtonGroup>
              ) : (
                <Button variant="outlined" onClick={open}>
                  Zobacz
                </Button>
              )}
            </Box>
          </Box>
        </div>
      </AccordionSummary>
      <AccordionDetails>
        <Stack direction="row" spacing={1} pt={2}>
          {moreInformation()}
        </Stack>
      </AccordionDetails>
    </CustomAccordion>
  );
};

export default TransportOrderListItem;
