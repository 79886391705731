import React from "react";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import HelpTemplate from "@shared/ui/templates/HelpTemplate";
import Typography from "@shared/ui/atoms/Typography";
import Panel from "@shared/ui/molecules/Panel/Panel";
import { useTranslation } from "react-i18next";
import jsxToString from "jsx-to-string";

const HowToCreateAnAccount = () => {
  const { i18n } = useTranslation();

  return (
    <HelpTemplate
      seoProps={{
        title: i18n.t("pages.howToCreateAnAccount.head.title"),
        structuredData: [
          {
            "@type": "FAQPage",
            mainEntity: [
              {
                "@type": "Question",
                name: "Jak założyć konto?",
                acceptedAnswer: {
                  "@type": "Answer",
                  text: jsxToString(
                    <p>
                      Wystarczy, że wypełnisz prawidłowo formularz
                      rejestracyjny, otrzymasz link aktywacyjny na podany
                      e-mail, w którego wystarczy kliknąć. Przejdź do{" "}
                      <strong>Zarejestruj się</strong> w TransKing.eu.
                    </p>
                  )
                }
              }
            ]
          }
        ]
      }}
    >
      <Grid item xs={12} sm={12} md={8} mt={3}>
        <Panel>
          <Stack spacing={2}>
            <Typography variant="h5" component="h1">
              Jak założyć konto?
            </Typography>
            <Box pt={1}>
              <Stack spacing={2}>
                <Typography>
                  Wystarczy, że wypełnisz prawidłowo formularz rejestracyjny,
                  otrzymasz link aktywacyjny na podany e-mail, w którego
                  wystarczy kliknąć.
                </Typography>
                <Typography>
                  Przejdź do{" "}
                  <Box display="inline" sx={{ color: "#1E99C0" }}>
                    <strong>Zarejestruj się</strong>{" "}
                  </Box>
                  w TransKing.eu.
                </Typography>
              </Stack>
            </Box>
          </Stack>
        </Panel>
      </Grid>
    </HelpTemplate>
  );
};

export default HowToCreateAnAccount;
