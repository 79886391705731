import React from "react";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import HelpTemplate from "@shared/ui/templates/HelpTemplate";
import Typography from "@shared/ui/atoms/Typography";
import Panel from "@shared/ui/molecules/Panel/Panel";
import { useTranslation } from "react-i18next";
import jsxToString from "jsx-to-string";

const WhereCanISeeOffersFromCarriers = () => {
  const { i18n } = useTranslation();

  return (
    <HelpTemplate
      seoProps={{
        title: i18n.t("pages.whereCanISeeOffersFromCarriers.head.title"),
        structuredData: [
          {
            "@type": "FAQPage",
            mainEntity: [
              {
                "@type": "Question",
                name: "Gdzie mogę podejrzeć oferty od przewoźników?",
                acceptedAnswer: {
                  "@type": "Answer",
                  text: jsxToString(
                    <ul>
                      <li>
                        Przejdź do zakładki <strong>Moje zlecenia</strong>
                      </li>
                      <li>
                        Wejdź w szczegóły interesującego Cię zlecenia za pomocą
                        przycisku <strong>Zobacz</strong>
                      </li>
                      <li>
                        Znajdź i kliknij w zakładkę <strong>Oferty</strong>
                      </li>
                      <li>Tam znajdziesz listę ofert od przewoźników</li>
                    </ul>
                  )
                }
              }
            ]
          }
        ]
      }}
    >
      <Grid item xs={12} sm={12} md={8} mt={3}>
        <Panel>
          <Stack spacing={2}>
            <Typography variant="h5" component="h1">
              Gdzie mogę podejrzeć oferty od przewoźników?
            </Typography>

            <Box pt={1}>
              <Stack spacing={2}>
                <ul style={{ listStyleType: "disc" }}>
                  <li>
                    <Typography>
                      Przejdź do zakładki{" "}
                      <Box display="inline-block">
                        <Typography color="text.turquoise.main">
                          <strong>Moje zlecenia</strong>
                        </Typography>
                      </Box>
                    </Typography>
                  </li>
                  <li>
                    <Typography>
                      Wejdź w szczegóły interesującego Cię zlecenia za pomocą
                      przycisku{" "}
                      <Box display="inline-block">
                        <Typography color="text.turquoise.main">
                          <strong>Zobacz</strong>
                        </Typography>
                      </Box>
                    </Typography>
                  </li>
                  <li>
                    <Typography>
                      Znajdź i kliknij w zakładkę{" "}
                      <Box display="inline-block">
                        <Typography color="text.turquoise.main">
                          <strong>Oferty</strong>
                        </Typography>
                      </Box>
                    </Typography>
                  </li>
                  <li>
                    <Typography>
                      Tam znajdziesz listę ofert od przewoźników
                    </Typography>
                  </li>
                </ul>
              </Stack>
            </Box>
          </Stack>
        </Panel>
      </Grid>
    </HelpTemplate>
  );
};

export default WhereCanISeeOffersFromCarriers;
