import React from "react";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import HelpTemplate from "@shared/ui/templates/HelpTemplate";
import Typography from "@shared/ui/atoms/Typography";
import Panel from "@shared/ui/molecules/Panel/Panel";
import { useTranslation } from "react-i18next";
import jsxToString from "jsx-to-string";

const WhereCanIViewAllMyIssuedOrders = () => {
  const { i18n } = useTranslation();

  return (
    <HelpTemplate
      seoProps={{
        title: i18n.t("pages.whereCanIViewAllMyIssuedOrders.head.title"),
        structuredData: [
          {
            "@type": "FAQPage",
            mainEntity: [
              {
                "@type": "Question",
                name: "Gdzie mogę podejrzeć wszystkie swoje wystawione zlecenia?",
                acceptedAnswer: {
                  "@type": "Answer",
                  text: jsxToString(
                    <p>
                      Wszystkie swoje zlecenia możesz podejrzeć, przechodząc do
                      zakładki <strong>Moje zlecenia</strong> w{" "}
                      <strong>Panelu użytkownika</strong>.{" "}
                    </p>
                  )
                }
              }
            ]
          }
        ]
      }}
    >
      <Grid item xs={12} sm={12} md={8} mt={3}>
        <Panel>
          <Stack spacing={2}>
            <Typography variant="h5" component="h1">
              Gdzie mogę podejrzeć wszystkie swoje wystawione zlecenia?
            </Typography>

            <Box pt={1}>
              <Stack spacing={2}>
                <Typography>
                  Wszystkie swoje zlecenia możesz podejrzeć, przechodząc do
                  zakładki{" "}
                  <Box display="inline-block">
                    <Typography color="text.turquoise.main">
                      <strong>Moje zlecenia</strong>
                    </Typography>
                  </Box>{" "}
                  w
                  <Box display="inline-block">
                    <Typography color="text.turquoise.main">
                      <strong>Panelu użytkownika</strong>
                    </Typography>
                  </Box>
                  .
                </Typography>
              </Stack>
            </Box>
          </Stack>
        </Panel>
      </Grid>
    </HelpTemplate>
  );
};

export default WhereCanIViewAllMyIssuedOrders;
