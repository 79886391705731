import React from "react";
import Box from "@mui/material/Box";
import Typography from "@shared/ui/atoms/Typography";
import { Stack } from "@mui/material";
import TransportOrderListItem from "@shared/ui/molecules/TransportOrderListItem/TransportOrderListItem";

const TransportOrderList: React.FC<any> = (props) => {
  const { transportOrders, refetchTransportOrders } = props;

  return (
    <>
      <Box
        sx={{
          display: { xs: "none", md: "block" }
        }}
      >
        <Stack direction="row" pl={2} alignItems="center" textAlign="center">
          <Box width="15%">
            <Typography variant="body2" color="text.turquoise.dark">
              <strong>Kategoria</strong>
            </Typography>
          </Box>
          <Box width="25%">
            <Typography variant="body2" color="text.turquoise.dark">
              <strong>Odbiór</strong>
            </Typography>
          </Box>
          <Box width="25%">
            <Typography variant="body2" color="text.turquoise.dark">
              <strong>Dostawa</strong>
            </Typography>
          </Box>
          <Box width="20%">
            <Typography variant="body2" color="text.turquoise.dark">
              <strong>Data publikacji</strong>
            </Typography>
          </Box>
          <Box width="15%" mr={5} />
        </Stack>
      </Box>
      <Stack spacing={1} mt={1}>
        {transportOrders.map((row: any) => (
          <TransportOrderListItem
            transportOrder={row}
            key={row.id}
            refetchTransportOrders={refetchTransportOrders}
          />
        ))}
      </Stack>
    </>
  );
};

export default TransportOrderList;
